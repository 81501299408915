import { useEffect, useRef } from "react";

export default ({ column, onChange, initialData, withTime }) => {
  const datepicker = useRef(null);

  useEffect(() => {
    if (datepicker.current) {
      const datepickerEl = window.$(datepicker.current).datepicker({
        language: "fr",
        autoClose: true,
        onSelect: (_, date) => {
          if (column.dataType === "datetimeUTC") {
            onChange({ [column.name]: date.toISOString() });
          } else {
            onChange({
              [column.name]: window
                .moment(date)
                .format(withTime ? "YYYY-MM-DD HH:mm:ss" : "YYYY-MM-DD"),
            });
          }
        },
      });
      if (initialData?.[column.name]) {
        if (column.dataType === "datetimeUTC") {
          datepickerEl
            .data("datepicker")
            .selectDate(new Date(initialData?.[column.name] + "Z"));
        } else {
          datepickerEl
            .data("datepicker")
            .selectDate(new Date(initialData?.[column.name]));
        }
      }
    }
  }, [datepicker.current]);

  return (
    <div>
      <input
        type="text"
        autocomplete="one-time-code"
        ref={datepicker}
        data-timepicker={withTime ? "true" : "false"}
      />
    </div>
  );
};
